import React from 'react'
import Content from "../../../Components/Dashboard/Content/Content"
import Loader from '../../../Utils/Loader'



const Support = () => {




    return <>
        {/* <Content Page_title="Support">
        </Content> */}
        )
    </>
}


export default Support
