import React from 'react';


const Loader = ({ fullPage = false }) => {
  return (
          <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }} class="loader"></div>

  );
}

export default Loader;
